import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import Vue3ColorPicker from 'vue3-colorpicker'

import App from './App.vue'
import router from './router'
import store from './store'

axios.defaults.baseURL = 'http://18.159.16.201:13600/'

createApp(App)
	.use(store)
	.use(router)
	.use(VueAxios, axios)
	.use(VueSweetalert2)
    .use(Vue3ColorPicker)
    .component('EasyDataTable', Vue3EasyDataTable)
	.mount('#app')
