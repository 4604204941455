import { createStore } from 'vuex'

export default createStore({
  state: {
    mapa: 0,
    page: '',
    login: 0,
    user: '',
    filter: null,
    filterConfig: null,
    load: 0,
    mapaAberto: 0,
    center: [39.882993, -9.646451],
    order: 0,
    block: 0,
    move: 0,
    add: 0,
    reset: 0,
    latest: 0,
    addpiquete: 0,
    upDta: ''
  },
  getters: {
  },
  mutations: {
    SETLOAD (state, a) {
      state.load = a
    },
    SETMAPA (state, a) {
      state.mapa = a
    },
    SETPAGE (state) {
      state.page = window.location.pathname.substring(1)
      state.mapaAberto = window.location.search.indexOf('?mapa=1') === 0
      if (state.mapaAberto) {
        state.mapa = 1
      }
    },
    SETLOGIN (state, a) {
      state.login = a
    },
    SETUSER (state, a) {
      state.user = a
    },
    SETFILTER (state, a) {
      state.filter = a
    },
    SETFILTERCONFIG (state, a) {
      state.filterConfig = a
    },

    SETORDER (state, a) {
      state.order = a
    },
    SETBLOCK (state, a) {
      state.block = a
    },
    SETMOVE (state, a) {
      state.move = a
    },
    SETADD (state, a) {
      state.add = a
    },
    SETADDPIQUETE (state, a) {
      state.addpiquete = a
    },
    SETRESET (state, a) {
      state.reset = a
    },
    SETLATEST (state, a) {
      state.latest = a
    },
    SETUPDATE (state, a) {
      state.upDta = a
    }
  },
  actions: {
  },
  modules: {
  }
})
