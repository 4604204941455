<template>
  <div class="lds-ripple" v-if="$store.state.load || $store.state.block"><div></div><div></div></div>
  <div style="width:100vw;height: 100vh;position:fixed;top:0px;left:0px;z-index:9;background: #ccc; opacity: 0.4;" v-if="$store.state.block"></div>

  <div class="modal" v-if="showModalSearch11">
    <div class="modal-content" style="max-width:500px;">
      <a class="closeModal" @click="showModalSearch11 = 0"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="#004B1F" class="bi bi-x" viewBox="0 0 16 16"> 
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
      </svg></a>
      <h4 class="linha" style="color:#333;">{{showModalSearch.text}}</h4>
      <div class="px-20">

        <div class="form-check checkLive">
          <input class="form-check-input checkLive1" v-model="selectAll" type="checkbox" id="selFiltros-all" @change="selectAllItems" />
          <label class="form-check-label checkLive2" style="padding: 3px 0px 0px 35px;width:100%;" for="selFiltros-all">
            <input placeholder="Pesquisar..." style="position:relative; top:-10px;color:#000;" type="text" class="form-control" v-model="searchTerm" @input="updateSelectAllStatus" />
          </label>
        </div>

        <div style="max-height:400px;overflow:auto;min-height:200px;">
          <div class="form-check checkLive" v-for="(dt, i) in filteredItems" :key="i">
            <input class="form-check-input checkLive1" :value="dt" type="checkbox" :id="'selFiltros-' + i" v-model="selectedItems" @change="filters1" />
            <label class="form-check-label checkLive2" style="padding: 3px 0px 0px 35px;" :for="'selFiltros-' + i"> {{colunaType == 'date' ? dataFN(dt) : dt}}</label>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div v-if="$store.state.login" :style="menuMobile ? 'margin-left: 60px' : ''" class="white-content modal-open">
    <div class="wrapper">
      <div class="sidebar" style="positionrelative;z-index:2;" v-if="!$store.state.mapaAberto">
        <div class="sidebar-wrapper">
          <ul class="nav">
            <li>
              <router-link to="/" class="popup">
                <span class="popuptext popuptextSidebar show">Live</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_409_14685)">
                    <path d="M7.76 16.2397C6.67 15.1597 6 13.6597 6 11.9997C6 10.3397 6.67 8.83969 7.76 7.75969L9.18 9.17969C8.45 9.89969 8 10.8997 8 11.9997C8 13.0997 8.45 14.0997 9.17 14.8297L7.76 16.2397ZM16.24 16.2397C17.33 15.1597 18 13.6597 18 11.9997C18 10.3397 17.33 8.83969 16.24 7.75969L14.82 9.17969C15.55 9.89969 16 10.8997 16 11.9997C16 13.0997 15.55 14.0997 14.83 14.8297L16.24 16.2397ZM12 9.99969C10.9 9.99969 10 10.8997 10 11.9997C10 13.0997 10.9 13.9997 12 13.9997C13.1 13.9997 14 13.0997 14 11.9997C14 10.8997 13.1 9.99969 12 9.99969ZM20 11.9997C20 14.2097 19.1 16.2097 17.65 17.6497L19.07 19.0697C20.88 17.2597 22 14.7597 22 11.9997C22 9.23969 20.88 6.73969 19.07 4.92969L17.65 6.34969C19.1 7.78969 20 9.78969 20 11.9997ZM6.35 6.34969L4.93 4.92969C3.12 6.73969 2 9.23969 2 11.9997C2 14.7597 3.12 17.2597 4.93 19.0697L6.35 17.6497C4.9 16.2097 4 14.2097 4 11.9997C4 9.78969 4.9 7.78969 6.35 6.34969Z" :fill="$route.name == 'home' ? '#ED6E00' : '#004B1F'"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_409_14685">
                      <rect width="24" height="24" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </router-link>
            </li>
            <li>
              <router-link to="piquetes" class="popup">
                <span class="popuptext popuptextSidebar show">Piquetes</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="bi bi-moon-stars" viewBox="0 0 16 16" fill="none"><path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" :fill="$route.name == 'piquetes' ? '#ED6E00' : '#004B1F'"></path><path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z" fill="#004B1F"></path></svg>
              </router-link>
            </li>
            <li class="hide-xs" v-if="$store.state.user.profile !== 'viewer'">
              <router-link to="planeamento" class="popup">
                <span class="popuptext popuptextSidebar show">Planeamento</span>
                <svg class="menu-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.0195 19.9305V21.9505C15.0295 21.7505 16.8595 20.9505 18.3395 19.7405L16.9195 18.3105C15.8095 19.1705 14.4795 19.7505 13.0195 19.9305Z" fill="#004B1F"></path>
                  <path d="M4.03027 12.0008C4.03027 7.95078 7.06027 4.59078 10.9803 4.07078V2.05078C5.95027 2.58078 2.03027 6.84078 2.03027 12.0008C2.03027 17.1608 5.95027 21.4208 10.9803 21.9508V19.9308C7.06027 19.4108 4.03027 16.0508 4.03027 12.0008Z" fill="#004B1F"></path>
                  <path d="M19.9501 10.9997H21.9701C21.7701 8.98969 20.9701 7.15969 19.7601 5.67969L18.3301 7.10969C19.1901 8.20969 19.7701 9.53969 19.9501 10.9997Z" fill="#004B1F"></path>
                  <path d="M18.3395 4.26078C16.8595 3.05078 15.0195 2.25078 13.0195 2.05078V4.07078C14.4795 4.25078 15.8095 4.83078 16.9195 5.69078L18.3395 4.26078Z" fill="#004B1F"></path>
                  <path d="M18.3301 16.9L19.7601 18.32C20.9701 16.84 21.7701 15.01 21.9701 13H19.9501C19.7701 14.46 19.1901 15.79 18.3301 16.9Z" fill="#004B1F"></path>
                  <path d="M16 11.1C16 8.61 14.1 7 12 7C9.9 7 8 8.61 8 11.1C8 12.76 9.33 14.73 12 17C14.67 14.73 16 12.76 16 11.1ZM12 12C11.41 12 10.93 11.52 10.93 10.93C10.93 10.34 11.41 9.86 12 9.86C12.59 9.86 13.07 10.34 13.07 10.93C13.07 11.52 12.59 12 12 12Z" :fill="$route.name == 'planeamento' ? '#ED6E00' : '#004B1F'"></path>
                </svg>
              </router-link>
            </li>
            <li>
              <router-link to="servicos" class="popup">
                <span class="popuptext popuptextSidebar show">Serviços</span>
                <svg class="menu-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_409_14396)">
                    <path d="M19.77 7.23L19.78 7.22L16.59 4.03C16.3 3.74 15.82 3.74 15.53 4.03C15.24 4.32 15.24 4.8 15.53 5.09L17.11 6.67C16.06 7.07 15.35 8.14 15.53 9.38C15.69 10.48 16.63 11.37 17.73 11.49C18.2 11.54 18.61 11.46 19 11.29V18.5C19 19.05 18.55 19.5 18 19.5C17.45 19.5 17 19.05 17 18.5V14C17 12.9 16.1 12 15 12H14V5C14 3.9 13.1 3 12 3H6C4.9 3 4 3.9 4 5V20C4 20.55 4.45 21 5 21H13C13.55 21 14 20.55 14 20V13.5H15.5V18.36C15.5 19.67 16.44 20.86 17.74 20.99C19.24 21.14 20.5 19.97 20.5 18.5V9C20.5 8.31 20.22 7.68 19.77 7.23ZM12 10H6V6C6 5.45 6.45 5 7 5H11C11.55 5 12 5.45 12 6V10ZM18 10C17.45 10 17 9.55 17 9C17 8.45 17.45 8 18 8C18.55 8 19 8.45 19 9C19 9.55 18.55 10 18 10Z" :fill="$route.name == 'servicos' ? '#ED6E00' : '#004B1F'"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_409_14396">
                      <rect width="24" height="24" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </router-link>
            </li>
            <li>
              <router-link to="servicos-remotos" class="popup">
                <span class="popuptext popuptextSidebar show">Serviços Remotos</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" :fill="$route.name == 'servicos-remotos' ? '#ED6E00' : '#004B1F'"><rect x="32" y="64" width="448" height="320" rx="32" ry="32" style="fill:none;stroke:#000;stroke-linejoin:round;stroke-width:32px"></rect><polygon points="304 448 296 384 216 384 208 448 304 448" style="stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px" :fill="$route.name == 'servicos-remotos' ? '#ED6E00' : '#004B1F'"></polygon><line x1="368" y1="448" x2="144" y2="448" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></line><path d="M32,304v48a32.09,32.09,0,0,0,32,32H448a32.09,32.09,0,0,0,32-32V304Zm224,64a16,16,0,1,1,16-16A16,16,0,0,1,256,368Z" :fill="$route.name == 'servicos-remotos' ? '#ED6E00' : '#004B1F'"></path></svg>
              </router-link>
            </li>
            <li>
              <router-link to="provisorios" class="popup">
                <span class="popuptext popuptextSidebar show">Provisórios</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16"> <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" :fill="$route.name == 'provisorios' ? '#ED6E00' : '#004B1F'"></path></svg>
              </router-link>
            </li>

            <li>
              <router-link to="subcontratados" class="popup">
                <span class="popuptext popuptextSidebar show">Subcontratados</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" :fill="$route.name == 'subcontratados' ? '#ED6E00' : '#004B1F'" class="bi bi-person-plus-fill" viewBox="0 0 16 16"> <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" :fill="$route.name == 'subcontratados' ? '#ED6E00' : '#004B1F'" /> </svg>
              </router-link>
            </li>
            <!--<li >
              <router-link to="/indicadores" class="popup">
                <span class="popuptext popuptextSidebar show">Indicadores</span>
                <svg class="menu-icon" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.8802 16.4698C19.3602 15.6998 19.6302 14.7998 19.5702 13.8098C19.4402 11.6598 17.7302 9.83981 15.6002 9.60981C12.8802 9.30981 10.5802 11.4198 10.5802 14.0798C10.5802 16.5698 12.5902 18.5798 15.0702 18.5798C15.9502 18.5798 16.7702 18.3198 17.4602 17.8798L19.8702 20.2898C20.2602 20.6798 20.9002 20.6798 21.2902 20.2898C21.6802 19.8998 21.6802 19.2598 21.2902 18.8698L18.8802 16.4698ZM15.0802 16.5798C13.7002 16.5798 12.5802 15.4598 12.5802 14.0798C12.5802 12.6998 13.7002 11.5798 15.0802 11.5798C16.4602 11.5798 17.5802 12.6998 17.5802 14.0798C17.5802 15.4598 16.4602 16.5798 15.0802 16.5798ZM14.7202 8.07981C13.9802 8.09981 13.2702 8.25981 12.6202 8.52981L12.0702 7.69981L8.99023 12.7098C8.63023 13.2898 7.82023 13.3498 7.38023 12.8398L5.26023 10.3698L2.20023 15.2698C1.89023 15.7598 1.23023 15.8898 0.760229 15.5498C0.340229 15.2398 0.220229 14.6598 0.500229 14.2098L4.28023 8.15981C4.64023 7.58981 5.45023 7.52981 5.89023 8.03981L8.00023 10.4998L11.1802 5.32981C11.5602 4.70981 12.4602 4.68981 12.8602 5.29981L14.7202 8.07981ZM17.3102 8.57981C16.6702 8.29981 15.9802 8.12981 15.2602 8.08981L19.8002 0.89981C20.1102 0.40981 20.7702 0.28981 21.2302 0.62981C21.6602 0.93981 21.7702 1.52981 21.4902 1.96981L17.3102 8.57981Z" :fill="$route.name == 'indicadores' ? '#ED6E00' : '#004B1F'"></path>
                </svg>
              </router-link>
            </li>-->
            <li>
              <router-link to="/equipas" class="popup">
               <span class="popuptext popuptextSidebar show">Equipas</span>
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_409_21861)">
                  <path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V18C1 18.55 1.45 19 2 19H14C14.55 19 15 18.55 15 18V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C15.05 13.06 15.06 13.08 15.07 13.09C16.21 13.92 17 15.03 17 16.5V18C17 18.35 16.93 18.69 16.82 19H22C22.55 19 23 18.55 23 18V16.5C23 14.17 18.33 13 16 13Z" :fill="$route.name == 'equipas' ? '#ED6E00' : '#004B1F'"></path>
                </g>
                <defs>
                  <clipPath id="clip0_409_21861">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </router-link>
          </li>
          <li>
            <router-link to="/zonas" class="popup">
              <span class="popuptext popuptextSidebar show">Zonas</span>
              <svg class="menu-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_409_21865)">
                  <path d="M4 10.2C4 5.22 7.8 2 12 2C16 2 19.64 4.92 19.97 9.5H22.29C22.74 9.5 22.96 10.04 22.64 10.35L19.35 13.64C19.15 13.84 18.84 13.84 18.64 13.64L15.35 10.35C15.04 10.04 15.26 9.5 15.7 9.5H17.96C17.65 6.24 15.13 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C12.64 18.75 13.23 18.18 13.77 17.63C13.6 17.29 13.5 16.91 13.5 16.51C13.5 15.13 14.62 14.01 16 14.01C17.38 14.01 18.5 15.13 18.5 16.51C18.5 17.89 17.38 19 16 19C15.76 19 15.53 18.97 15.31 18.9C14.53 19.72 13.64 20.56 12.66 21.42C12.28 21.75 11.71 21.75 11.33 21.42C6.45 17.12 4 13.38 4 10.2Z" :fill="$route.name == 'zonas' ? '#ED6E00' : '#004B1F'"></path>
                </g>
                <defs>
                  <clipPath id="clip0_409_21865">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </router-link>
          </li>
          <li>
            <router-link to="/preventivas" class="popup">
              <span class="popuptext popuptextSidebar show">Preventivas</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.5,13H8v-3h2.5V7.5h3V10H16v3h-2.5v2.5h-3V13z M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2 z" :fill="$route.name == 'preventivas' ? '#ED6E00' : '#004B1F'"/></svg>
            </router-link>
          </li>
          <li>
            <router-link to="/configuracoes" class="popup">
              <span class="popuptext popuptextSidebar show">Configurações</span>
              <svg  class="menu-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.1401 12.9399C19.1801 12.6399 19.2001 12.3299 19.2001 11.9999C19.2001 11.6799 19.1801 11.3599 19.1301 11.0599L21.1601 9.4799C21.3401 9.3399 21.3901 9.0699 21.2801 8.8699L19.3601 5.5499C19.2401 5.3299 18.9901 5.2599 18.7701 5.3299L16.3801 6.2899C15.8801 5.9099 15.3501 5.5899 14.7601 5.3499L14.4001 2.8099C14.3601 2.5699 14.1601 2.3999 13.9201 2.3999H10.0801C9.84011 2.3999 9.65011 2.5699 9.61011 2.8099L9.25011 5.3499C8.66011 5.5899 8.12011 5.9199 7.63011 6.2899L5.24011 5.3299C5.02011 5.2499 4.77011 5.3299 4.65011 5.5499L2.74011 8.8699C2.62011 9.0799 2.66011 9.3399 2.86011 9.4799L4.89011 11.0599C4.84011 11.3599 4.80011 11.6899 4.80011 11.9999C4.80011 12.3099 4.82011 12.6399 4.87011 12.9399L2.84011 14.5199C2.66011 14.6599 2.61011 14.9299 2.72011 15.1299L4.64011 18.4499C4.76011 18.6699 5.01011 18.7399 5.23011 18.6699L7.62011 17.7099C8.12011 18.0899 8.65011 18.4099 9.24011 18.6499L9.60011 21.1899C9.65011 21.4299 9.84011 21.5999 10.0801 21.5999H13.9201C14.1601 21.5999 14.3601 21.4299 14.3901 21.1899L14.7501 18.6499C15.3401 18.4099 15.8801 18.0899 16.3701 17.7099L18.7601 18.6699C18.9801 18.7499 19.2301 18.6699 19.3501 18.4499L21.2701 15.1299C21.3901 14.9099 21.3401 14.6599 21.1501 14.5199L19.1401 12.9399ZM12.0001 15.5999C10.0201 15.5999 8.40011 13.9799 8.40011 11.9999C8.40011 10.0199 10.0201 8.3999 12.0001 8.3999C13.9801 8.3999 15.6001 10.0199 15.6001 11.9999C15.6001 13.9799 13.9801 15.5999 12.0001 15.5999Z" :fill="$route.name == 'configuracoes' ? '#ED6E00' : '#004B1F'"></path>
              </svg>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-panel" style="position:relative; z-index: 1;">
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent" style="height: 60px;" v-if="!$store.state.mapaAberto">
        <div class="container-fluid">
          <div class="navbar-wrapper">
            <div :class="{'navbar-toggle d-inline':1, toggled: menuMobile}">
              <button type="button" class="navbar-toggler"  @click="FNmenuMobile()">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <router-link class="navbar-brand" to="/"><img src="./assets/img/petroassist2.png" style="height: 26px; margin-top:5px;"></router-link>
          </div>
          <!--<router-link class="navbar-toggler" @click="SETMAPA(1)" target="_blank" :to="'/' + $store.state.page + '?mapa=1'">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/> <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/> </svg>
          </router-link>-->
          <a class="nav-link navbar-toggler">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="logout()">
              <path d="M2.16667 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H7.16667C7.625 15.5 8 15.125 8 14.6667C8 14.2083 7.625 13.8333 7.16667 13.8333H2.16667V2.16667Z" fill="white"></path>
              <path d="M15.2083 7.70811L12.8833 5.38311C12.6167 5.11645 12.1667 5.29978 12.1667 5.67478V7.16645H6.33333C5.875 7.16645 5.5 7.54145 5.5 7.99978C5.5 8.45811 5.875 8.83311 6.33333 8.83311H12.1667V10.3248C12.1667 10.6998 12.6167 10.8831 12.875 10.6164L15.2 8.29145C15.3667 8.13312 15.3667 7.86645 15.2083 7.70811Z" fill="white"></path>
            </svg>
          </a>
          <!--
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
          </button>-->
          <div class="collapse navbar-collapse" id="navigation">
            <ul class="navbar-nav ml-auto">
              <li v-if="($store.state.page === 'servicos' || $store.state.page === '' || $store.state.page === 'planeamento' || $store.state.page === 'servicos-remotos' || $store.state.page === 'provisorios' || $store.state.page === 'piquetes') && !$store.state.mapa">
                <router-link class="dropdown-toggle nav-link popup" @click="SETMAPA(1)" target="_blank" :to="'/' + $store.state.page + '?mapa=1'">
                  <span class="popuptext popuptextSidebar show">Dividir Página</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/> <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/> </svg>
                </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  {{$store.state.user.name}}
                  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="logout()">
                    <path d="M2.16667 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H7.16667C7.625 15.5 8 15.125 8 14.6667C8 14.2083 7.625 13.8333 7.16667 13.8333H2.16667V2.16667Z" fill="white"></path>
                    <path d="M15.2083 7.70811L12.8833 5.38311C12.6167 5.11645 12.1667 5.29978 12.1667 5.67478V7.16645H6.33333C5.875 7.16645 5.5 7.54145 5.5 7.99978C5.5 8.45811 5.875 8.83311 6.33333 8.83311H12.1667V10.3248C12.1667 10.6998 12.6167 10.8831 12.875 10.6164L15.2 8.29145C15.3667 8.13312 15.3667 7.86645 15.2083 7.70811Z" fill="white"></path>
                  </svg>
                </a>
              </li>
              <li class="separator d-lg-none"></li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- End Navbar -->
          <div class="content" :style="$store.state.mapaAberto ? 'padding:0px;' : 'padding-top: 60px;padding-bottom: 0px;'">
            <div v-if="user.profile == 'planner' && !user.planner_profile_id" style="margin-top:10px;" class="alert alert-danger">O utilizador {{user.name}} não tem um perfil de planeador definido. Por favor entre em contacto com um administrador para que este lhe possa ser configurado.</div>
              <router-view></router-view>
          </div>
        </div>
      </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
// import './assets/css/nucleo-icons.css'
import './assets/css/dashboard.css'
import 'leaflet/dist/leaflet.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue3-easy-data-table/dist/style.css'
import 'vue3-colorpicker/style.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import { mapMutations } from 'vuex'

export default {
  name: 'LoginView',
  data () {
    return {
      menuMobile: false,
      user: {},

      showModalSearch: {},
      showModalSearch11: 0,

      searchTerm: '',
      selectAll: false,
      selectedItems: [],
      res: [],
      filter: [],
      colunaType: ''
    }
  },
  watch: {
    '$store.state.filter': {
      handler (val) {
        if (!val) {
          this.filter = []
          this.res = []
        }
      },
      deep: true
    },
    '$store.state.filterConfig': {
      handler (val) {
        if (val) {
          this.res = val.res
          this.showModalSearch = val.header
          if (Array.isArray(this.$store.state.filter)) {
            this.selectedItems = (this.$store.state.filter.find(a => a.field === val.header.value) || { criteria: [] }).criteria
          } else {
            this.selectedItems = []
          }
          this.selectAll = this.selectedItems.length === val.res.length
          this.searchTerm = ''
          this.showModalSearch11 = 1
          this.colunaType = val.type
        }
      },
      deep: true
    }
  },
  computed: {
    filteredItems () {
      const key = this.showModalSearch.value
      const c = this.removerDuplicatas(this.res.filter(item => (item[key] || '').toString().toLowerCase().includes(this.searchTerm.toLowerCase())), key)
      return c.map(a => a[key])
    }
  },
  mounted () {
    if (localStorage.getItem('token') && new Date(localStorage.getItem('token_expired')) > new Date()) {
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
      this.axios.get('api/auth/whois', this.form).then((res) => {
        this.SETUSER(res.data.user)
        this.SETLOGIN(1)
        this.SETPAGE()
      }, () => {
        localStorage.removeItem('token')
        localStorage.removeItem('token_expired')
        this.SETLOGIN(0)
        this.SETUSER('')
      })
    } else {
      this.SETLOGIN(0)
      this.$router.replace('/login')
    }
    this.axios.get('api/auth/whois').then((res) => {
      this.user = res.data.user
    }, () => {})
  },
  methods: {
    ...mapMutations([
      'SETMAPA',
      'SETPAGE',
      'SETLOGIN',
      'SETUSER',
      'SETLOAD',

      'SETFILTER'
    ]),
    dataFN (a, data) {
      if (!a) {
        return ''
      }
      const dd = new Date(a)
      if (data) {
        return ('0' + dd.getDate()).slice(-2) + '-' + ('0' + (1 + dd.getMonth())).slice(-2) + '-' + dd.getFullYear()
      }
      return ('0' + dd.getDate()).slice(-2) + '-' + ('0' + (1 + dd.getMonth())).slice(-2) + '-' + dd.getFullYear() + ' ' + ('0' + dd.getHours()).slice(-2) + ':' + ('0' + dd.getMinutes()).slice(-2)
    },

    removerDuplicatas (array, chave) {
      const chaveSet = new Set()
      return array.filter(item => {
        const chaveValor = item[chave]
        if (!chaveSet.has(chaveValor ? chaveValor.toString().toLowerCase() : null)) {
          chaveSet.add(chaveValor ? chaveValor.toString().toLowerCase() : null)
          return true
        }
        return false
      })
    },
    selectAllItems () {
      if (this.selectAll) {
        this.selectedItems = this.filteredItems.map(a => a).concat(this.selectedItems)
        this.selectedItems = this.selectedItems.filter((a, i) => this.selectedItems.indexOf(a) === i)
      } else {
        // this.selectedItems = this.selectedItems.filter(a => !this.filteredItems.find(b => b === a))
        this.selectedItems = this.selectedItems.filter(a => a !== null && !this.filteredItems.find(b => b === a))
      }
      this.filters1()
    },
    updateSelectAllStatus () {
      this.selectAll = this.filteredItems.every(a => this.selectedItems.includes(a))
    },
    filters1 () {
      let i = this.filter.findIndex(a => a.field === this.showModalSearch.value)
      if (i >= 0) {
        this.filter.splice(i, 1)
      }

      if (this.selectedItems.length) {
        this.filter.push({
          field: this.showModalSearch.value,
          criteria: [...this.selectedItems],
          comparison: (value, criteria) => {
            return criteria.find(a => (a || '').toString().toLowerCase() === (value || '').toString().toLowerCase()) || criteria.includes((value || '') || null)
            // return criteria.find(a => (a || '').toString().toLowerCase() === (value || '').toString().toLowerCase()) || (criteria || '').toString().toLowerCase().includes((value || '').toString().toLowerCase() || null) && !value 
          }
        })
      }
      this.SETFILTER(this.filter)
    },

    FNmenuMobile () {
      this.menuMobile = !this.menuMobile
      if (this.menuMobile) {
        document.getElementById('html').classList.add('nav-open')
      } else {
        document.getElementById('html').classList.remove('nav-open')
      }
    },
    logout () {
      this.SETLOAD(1)
      this.axios.delete('api/auth/logout').then(() => {
        this.axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        localStorage.removeItem('token_expired')
        this.SETLOGIN(0)
        this.SETUSER('')
        this.$router.push('/login')
      }, () => {
        this.axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        localStorage.removeItem('token_expired')
        this.SETLOGIN(0)
        this.SETUSER('')
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style>
.vue3-easy-data-table__main {background:transparent !important;}
tr:not(.tr-danger):nth-child(even), tr:not(.tr-danger):nth-child(even)>td {background:#e9e9e9 !important}

tr.tr-danger, .tr-danger td, tr.tr-danger:nth-child(even) , .tr-danger:nth-child(even) td{
  --easy-table-body-row-background-color: #f56c6c !important;
  background-color: #f56c6c !important;
  --easy-table-body-row-font-color: #fff !important;
  color: #fff !important;
}

.is_locked, .is_locked td, tr:nth-child(even).is_locked, tr:nth-child(even).is_locked td{
   background: #ddd !important;
   color: #373737 !important;
}
a, .mao, .icon-svg {cursor:pointer}
.preto {color:#111 !important;}

.mapaIcones {
  background-color: transparent;
  border: none;
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: fixed;
  width: 80px;
  height: 80px;
  top: calc(50vh - 40px);
  left: calc(50vw - 40px);
  z-index: 99999999;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}


.popup {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  display: none;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  font-size:14px;
  text-align:left;
  white-space: pre-line; 
}
.popup .popuptextCompetencia, .popup .popuptextServicos, .popup .popuptextHome, .popup .popuptextPlaneamento {width:260px;margin-left: -130px;bottom: auto; top: calc(100% + 11px);height: auto;}
.popup .popuptextSidebar {left: 60px; top: 10px;margin-left: 0px;bottom: auto;width: auto;}
.popup .popuptextPreventivas {left: -110px;top: 22px;margin-left: 0px;bottom: auto;width: 220px; color:#fff;}
.popup .popuptextSidebar1 {left: 20px; top: -10px;margin-left: 0px;bottom: auto;width: 190px;}
.popup .popuptextHome { top: -32px;left: 100%;bottom: auto;margin:0;}

.popup .popuptextPlaneamento {top: 25px}

.popup .popuptextServicos {width:auto;margin: auto;bottom: auto; top: calc(100% + 11px);height: auto;left:-10px;}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.popup .popuptextCompetencia::after, .popup .popuptextServicos:after {
  top: -10px;
  border-color: transparent transparent #555 transparent;
  left: 50%;
}
.popup .popuptextServicos:after {
  top: -10px;
  border-color: transparent transparent #555 transparent;
  left: 10px;
}


.popup .popuptextSidebar::after {
  left: -5px;
  border-color: transparent #555 transparent transparent ;
  top: 15px;
}
.popup .popuptextSidebar1::after {
  left: -5px;
  border-color: transparent #555 transparent transparent ;
  top: 15px;
}
.popup .popuptextPreventivas::after {display:none;}

.popup .popuptextHome::after {
  left: -5px;
  top: 34px;
  border-color: transparent #555 transparent transparent;
}
.popup .popuptextPlaneamento::after {
  left: 50%;
  border-color: transparent transparent #555 transparent ;
  top: -10px;
}

/* Toggle this class - hide and show the popup */
.popup:hover .popuptext {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

.p-l-5 {padding-left:5px;}
.p-t-5 {padding-top:5px}
.p-l-10 {padding-left:10px;}
.p-t-10 {padding-top:10px}
.p5 {padding: 5px;}
.m-0 {margin: 0}

.vtl-paging-change-div {display:none;}

th {width: auto;white-space: nowrap;}

.tr-danger .easy-checkbox{display:none}
.is_locked .easy-checkbox{display:none}

.check-alert-columns {width: 100%;float:left;text-align:left;position:relative;color:#000;}
.check-alert-columns label {padding-left: 35px;}

.checkLive input[type='checkbox'], .check-alert-columns input[type='checkbox'] {
      outline: 0;
    visibility: hidden;
    width: 1.3em;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;
    accent-color: white;
    cursor: pointer;
        -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    place-content: center;
        box-sizing: border-box;
    padding: 0;

}
.checkLive label, .check-alert-columns label {
  cursor: pointer;
    display: inline;
    line-height: 1.3em;
    vertical-align: top;
    clear: both;
        box-sizing: border-box;
        margin-bottom: 0.5rem;
            top: 7px;
    position: absolute;
    left: 4px;
}

.checkLive label:before, .check-alert-columns label:before {
    width: 1.3em;
    height: 1.3em;
    background: #fff;
    border: 1px solid rgba(0,0,0,.54);
    border-radius: 0.125em;
    cursor: pointer;
    transition: background .3s;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
}

.checkLive label:after, .check-alert-columns label:after {
      content: "";
    position: absolute;
    left: 0;
    top: 0;
        box-sizing: border-box;
}

.checkLive input[type='checkbox']:check, .check-alert-columns input[type='checkbox']:check {background: #42b883;}

.checkLive input[type=checkbox]:checked+label:before, .check-alert-columns input[type=checkbox]:checked+label:before {
    background: #42b883;
    border: none;
}

.checkLive input[type=checkbox]:checked+label:after, .check-alert-columns input[type=checkbox]:checked+label:after  {
    transform: translate(0.2em,0.3038461538em) rotate(-45deg);
    width: 0.9em;
    height: 0.45em;
    border: 0.13em solid #fff;
    border-top-style: none;
    border-right-style: none;
}



/* The Modal (background) */
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px 0px;
  border: 1px solid #888;
  width: calc(100% - 180px);
  position:relative;
  left:64px;
}

.pr {position:relative;}


.saveRotas {position: absolute;right: -7px; bottom: 0px;padding:0px;}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #004B1f;
}

.tabelaHover table tbody tr:hover td {
  background-color: #ddd !important;
  cursor: pointer
}

.slider-connect {background:transparent;}
.slider-vertical {position: fixed; right: 8px; top: 260px;height: calc(100vh - 442px)}


.block-1 {
  overflow: auto;
  min-height:300px;
  height: 350px;
}

.block-2 {
  flex: 1;
  min-height: 300px;
}

.slider {display:none;}

@media screen and (min-height:800px){
  .outer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
  }

  .block-1 {
    resize: vertical;
    min-height:300px;
  }

  .block-2 {
    overflow: hidden;
  }

  .slider {
    text-align: center;
    letter-spacing: 10px;
    background-color: #dee2e6;
    cursor: row-resize;
    user-select: none;
    display:block;
  }
}

.hide:has(input.checkLive1:not(:checked)), .hide:has(input:not(:checked)) {display:none;}

div:where(.swal2-container) .swal2-html-container {overflow:initial;}

.linha {border-bottom: solid 1px #004B1F;padding-left:20px;}
.green-div {
  background-color: white;
  color: #333;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  width: 1199px;
  margin-bottom: 3px;
  margin-top:10px;
}

.white-div {
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.column {
  display: inline-block;
  padding: 5px;
}

.btn-cancel {background: #fff !important;color: #004B1F !important;}
.btn-cancel:hover, .btn-cancel:focus, .btn-cancel:active {background: #fff !important;color: #004B1F !important;}
.closeModal {position: absolute; top: 20px; right: 18px;}

.px-20 {padding-left: 20px;padding-right: 20px;}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {background-color: #004B1F;}

.liveLinha {height:0px;}

.red{color:red}
.verde {color: #004B1F}
.f-verde, th {background: #E5EDE9 !important}
.cinza {background:#eee}

.dp__action_select, .dp__active_date {background: #004B1F !important;}
.dp__today {border-color: #004B1F !important;}

.alert-danger {color: #721c24;background-color: #f8d7da;border-color: #f5c6cb;}

input[type=radio] {
  accent-color: #004B1F;
}

.buttons-pagination .item.button.active {background: #004B1F ! important; border: none !important}

.modal-sub-title {margin: 0px;font-weight:bold;}

.heightAlert {max-height: 400px; overflow: auto; display: block;}

.tabelaCompetencia table thead th {padding-bottom: 30px !important;}

#tabs-2-service-home table th:nth-child(2) {
  width:22px !important;
}

@media (max-width: 800px) {
  .hide-xs, .slider {
    display:none !important;
  }
  .table-grid-left {position: relative;}
}
@media (min-width: 800px) {
  .vis-xs {
    display:none !important;
  }
}

div:where(.swal2-container) div:where(.swal2-popup) {width: auto;}

.expand {padding:0px !important;}

.divSemana {min-width: 200px;margin: 0px 0.5% 20px 0.5%; border: solid 1px #cccc;float:left; width: 24%;border-radius: 5px;}
.divFimSemana {min-width: 260px;margin: 0px 1% 0px 1%; border: solid 1px #cccc;float:left; width: 25%;border-radius: 5px;}
.diaSemana {background:#669379;line-height:30px;padding: 5px;color:#fff;margin:5px;border-radius: 5px;}
.addSemana {margin: 5px; border-radius: 5px; border: solid 1px #ccc;line-height: 30px;display:block;width: calc(100% - 10px);}
.equipaSemana {margin: 5px; border-radius: 5px; border: solid 1px #ccc;display:block;width: calc(100% - 10px);padding: 5px 25px 5px 5px;}
.delEquipa {position: absolute; bottom: calc(50% - 10px); right:5px;}
.editEquipa {position: absolute; bottom: calc(50% - 10px); right:30px;}

.heightConfiguracoes {overflow: auto;height: calc(100vh - 250px);min-height: 300px;}
.heightConfiguracoes1 {overflow: auto;height: calc(50vh - 190px);min-height: 200px;}

.switchinput[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchlabel {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 20px;
  background: grey;
  border-radius: 100px;
  position: relative;
  top: 5px;
}

.switchlabel:after {
  content: '';
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.switchinput:checked + .switchlabel {
  background: #004B1F;
}

.switchinput:checked + .switchlabel:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.switchlabel:active:after {
  width: 30px;
}

.rotating-image {
    margin: -2px 0px 0px 2px;
    width: 16px;
    height: 16px;
    animation: rotate 5s linear infinite;
    transform-origin: 8px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.liveExclamacao {position: absolute;top: 13px;right: 3px;display:none;}

.closed1  {opacity: 35%;}

.tablePlaneamentoServiceHover:hover {z-index:2 !important;}

.timeline-box-innner:hover {position:relative; z-index:21 !important;}

.vue3-easy-data-table__header {z-index:3}

.border_planner {border: solid 1px #ccc; padding: 5px;border-radius: 10px;display: inline-block; margin-right: 10px;}


th .vue-treeselect__multi-value-item {display:none;}

.timeline-box-innner:last-child {padding-bottom:200px;}

.famarelo {background: #eead2d}
.fverde {background:#004B1F}
.fvermelho {background:#CF0E0E;}

.acordeon {cursor: pointer;
  padding: 10px;
  background-color: #eee;
  border: 1px solid #ddd;
}
</style>
